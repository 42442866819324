<template>
  <div>
    <validation-observer
      ref="simpleRules"
      v-slot="{ handleSubmit }"
    >
      <b-card
        no-body
      >
        <template #header>
          <h4 class="mr-auto mb-0">
            PROFILE
          </h4>
          <b-button
            v-if="!editMode"
            variant="primary"
            size="sm"
            @click="editMode = true"
          >
            Edit
          </b-button>
        </template>
        <template
          #footer
        >
          <b-button
            type="submit"
            variant="primary"
            @click="handleSubmit(onSubmit)"
          >
            Submit
          </b-button>
          <b-button
            v-if="editMode"
            class="ml-1"
            type="reset"
            variant="secondary"
            @click="cancelForm"
          >
            Cancel
          </b-button>
        </template>

        <!-- group Configurations-->
        <div class="p-2">
          <b-row>
            <b-col cols="3">
              <h6 class="m-0">
                <strong>Login Information</strong>
              </h6>
            </b-col>
            <b-col>
              <b-row>
                <b-col
                  cols="6"
                >
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules=""
                        >
                          <label>Email </label>
                          <b-form-input
                            v-model="actualForm.email"
                            size="sm"
                            :plaintext="!editMode"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="New Password"
                          rules=""
                        >
                          <label>New Password </label>
                          <b-form-input
                            v-model="actualForm.new_password"
                            size="sm"
                            :plaintext="!editMode"
                            :state="errors.length > 0 ? false:null"
                            placeholder="New Password"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Confirm New Password"
                          rules=""
                        >
                          <label>Confirm New Password </label>
                          <b-form-input
                            v-model="actualForm.confirm_password"
                            size="sm"
                            :plaintext="!editMode"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Confirm New Password"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <!-- end group Configurations-->
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      actualForm: {
        email: 'sdsdsd',
      },
      editMode: false,
    }
  },
  computed() {

  },
  async mounted() {
    this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Profile' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
  },
  methods: {
    cancelForm() {
      this.editMode = false
    },
  },
}
</script>

<style>

</style>
